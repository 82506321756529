
import Vue from 'vue'

export default Vue.extend({
  name: 'ExportList',

  data: () => {
    return {
      columns: [
        {
          label: 'Nutzer',
          field: 'username',
          sortable: false,
        },
        {
          label: 'Datum',
          field: 'created_at',
          sortable: false,
        },
        {
          label: 'Aktion',
          field: 'action',
          width: '110px',
          sortable: false,
        },
      ],
      exportCount: 0,
      interval: null as any,
      isLoading: true,
      limit: 10,
      page: 1,
      total: 0,
    }
  },

  computed: {
    awards (): any[] {
      return this.$store.state.awards?.awards
    },
    baseUrl (): string {
      // in this case we don't need the extra /
      return this.$store.state.baseUrl.slice(0, -1)
    },
    currentAward (): any {
      return this.$store.state.currentAward
    },
    currentExportConf (): any {
      return this.$store.state.currentExportConf
    },
    exportlist (): any {
      return this.$store.state.exports
    },
  },

  methods: {
    createExport (ex: any) {
      if (!ex.value) { 
        this.$store.commit('setAlert', {
          type: 'info',
          message: 'Bitte wälen sie eine Option im Drodown, um einen Export zu starten.'
        })
      } else {
        this.isLoading = true
        this.$store.dispatch('postExport', {
          awardType: this.currentAward.awardType,
          year: this.currentAward.year,
          ex: ex.key,
          option: ex.value,
        }).then(this.filterList)
      }
      return false
    },

    deleteExport (e: any) {
      const yes = confirm("Wollen Sie diesen Export wirklich löschen?")
      if (yes) {
        this.isLoading = true
        this.$store.dispatch('deleteExport', {
          id: e.id,
        }).then(this.filterList)
      }
      return false
    },

    gotoPage (i: any) {
      this.page = i
      this.filterList()
    },

    filterList () {
      this.isLoading = true
      this.init()
    },

    formatTimestamp (time: any) {
      const date = new Date(time)
      const options: any = { year: '2-digit', month: 'numeric', day: 'numeric', minute: '2-digit', hour: '2-digit' };
      return date.toLocaleString('de', options)
    },

    getText(e: any) {
      let res = ''
      const exp = this.currentExportConf?.exports.filter((e1: any) => e1.key === e.exportKey)[0]
      if (exp) {
        res += exp.name
        const opt = exp.option.filter((o: any) => o.key === e.optionKey)[0]
        if (opt) {
          res += ': ' + opt.name
        }
      }
      res += ' - Status: '
      switch (e.status) {
        case 'waiting':
          res += 'wartend'
          break;
        case 'running':
          res += 'läuft'
          break;
        case 'finished':
          res += 'fertig'
          break;
        case 'failed':
          res += 'fehlgeschlagen'
          break;
        default:
          break;
      }
      return res
    },

    init () {
      this.$store.dispatch('getExports', {
        awardType: this.currentAward.awardType,
        year: this.currentAward.year,
        page: this.page,
      }).then((exs) => {
        this.exportCount = exs.count
        this.isLoading = false
      })
    },

    statusColor (status: any) {
      let res = ''
      switch (status) {
        case 'waiting':
          res += 'orange'
          break;
        case 'running':
          res += 'yellow'
          break;
        case 'finished':
          res += 'green'
          break;
        case 'failed':
          res += 'red'
          break;
        default:
          break;
      }
      return res
    },

    statusIcon (status: any) {
      let res = ''
      switch (status) {
        case 'waiting':
          res += 'circle'
          break;
        case 'running':
          res += 'minus-circle'
          break;
        case 'finished':
          res += 'check-circle'
          break;
        case 'failed':
          res += 'times-circle'
          break;
        default:
          break;
      }
      return res
    },
  },

  created () {
    this.filterList()
    this.interval = setInterval(() => {
      console.log('interval: ', this.interval);
      this.init()
    }, 20000);
  },
  
  destroyed () {
    this.filterList()
    clearInterval(this.interval)
  },

})
